.team_member {
    padding: 28px 44px;
    display: flex;
    align-items: center;
    background-color: #F8F8F8;
    border-radius: 11px;
}

.team_member + .team_member {
    margin-top: 3px;
}

.team_member_photo {
    width: 115px;
    height: 115px;
    overflow: hidden;
    margin-right: 30px;
    flex: 1 0 auto;
}

.team_member_photo img {
    display: block;
    min-width: 100%;
    min-height: 100%;
    /* width: 100%; */
    height: 100%;;
}

@media screen and (max-width: 480px) {
    .team_member {
        flex-direction: column;
    }

    .team_member_photo {
        margin-right: 0;
        margin-bottom: 20px;
    }
    .team_member h3 {
        margin-bottom: 10px;
    }
}