.home_hero {
    flex: 1 0 auto;
    width: 100%;
    height: calc(100vh - 64px);
    background-image: url(./hero_bg.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.home_hero_title_wrapper {
    margin-top: auto;
    margin-bottom: 78px;
    text-align: center;
    color: #fff;
}

.home_hero_subtitle {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 25px;
}

.home_hero_title {
    font-weight: bold;
    font-size: 60px;
    line-height: 90px;
}