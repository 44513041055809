.hero {
    background-color: #FAF6E8;
    width: 100%;
    height: 283px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
    display: flex;
    flex-direction: column;
}

.hero_content {
    flex: 1 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: #fff;
}

.hero_subtitle {
    font-weight: bold;
    font-size: 30px;
}

.hero_title {
    font-weight: bold;
    font-size: 60px;
    margin-top: 17px;
    margin-bottom: 70px;
    line-height: 72px;
}

@media screen and (max-width: 767px) { 
    .hero {
        height: 150px;
    }

    .hero_subtitle {
        font-size: 18px;
    }
    
    .hero_title {
        font-weight: bold;
        font-size: 26px;
        margin-top: 10px;
        margin-bottom: 35px;
        line-height: 30px;
    }
}