.policy_content_wrapper {
    background-color: #fff;
    flex: 1 0 auto;
}

.policy_content {
    margin-bottom: 118px;
}

.policy_content p + p {
    margin-top: 5px;
}

.policy_content ul {
    margin: 10px 0;
}

.policy_content ul li {
    position: relative;
    display: block;
    padding-left: 30px;
}

.policy_content ul li span {
    font-weight: bold;
}

.policy_content ul li + li {
    margin-top: 10px;
}

.policy_content ul li:before {
    display: block;
    position: absolute;
    content: '';
    width: 5px;
    height: 5px;
    background-color: #000;
    top: 6px;
    left: 0;
    border-radius: 50%;
} 