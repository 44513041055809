.socials_wrapper {
    display: flex;
}

.social_item {
    width: 48px;
    height: 48px;
    border-radius: 14px;
    background-color: #D9D9D9;
    cursor: pointer;
    display: block;
}

.social_item + .social_item {
    margin-left: 14px;
}