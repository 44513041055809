.header_wrapper {
    background-color: #000;
    padding: 9px 0 7px;
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    height: 64px;
    box-sizing: border-box;
    z-index: 3;
}

.header_wrapper a,
.header_wrapper a span {
    font-weight: bold;
    font-size: 17px;
}

.header_content_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_navigation {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.header_link_wrapper {
    padding-left: 40px;
}

.header_link_home_wrapper {
    display: flex;
    align-items: center;
} 

.header_link_home_text {
    display: block;
    padding-left: 4px;
}

.header_link,
.header_link:visited {
    display: block;
    color: #fff;
    padding: 4px 17px 5px;
    border-radius: 5px;
    background-color: transparent;
    position: relative;
    transition: all .3s ease;
}

.header_link svg {
    fill: #fff;
    transition: all .3s ease;
}

.header_link_home {
    padding-bottom: 0;
}

.header_link:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -9px;
    width: 0;
    height: 3px;
    background-color: #25E5FF;
    border-radius: 1px;
    transition: all .3s ease;
}

.header_link:hover,
.header_link:active,
.header_link:focus {
    border: none;
    outline: none;
    background-color: #3C3C3C;
    color: #25E5FF;
}

.header_link:hover svg,
.header_link:active svg,
.header_link:focus svg {
    fill: #25E5FF;
}

.header_link:hover::after,
.header_link:active::after,
.header_link:focus::after {
    width: 100%;
}

.header_button {
    padding: 5px 26px 6px;
    color: #fff;
    background: linear-gradient(70deg, rgba(167,22,255,1) 0%, rgba(255,122,0,1) 100%);
    border-radius: 10px;
    font-size: 17px;
    font-weight: bold;
    line-height: 22px;
    cursor: pointer;
    margin-left: auto;
    box-sizing: border-box;
}

.header_open_menu {
    display: none;
}

@media screen and (max-width: 767px) {
    .header_mobile_menu {
        display: block;
        position: absolute;
        width: 100%;
        top: 100%;
        height: 0;
        overflow: hidden;
        transition: height .3s ease;
        background-color: rgba(0, 0, 0);
    }

    .header_mobile_menu_opened {
        height: 198px;
    }

    .header_navigation {
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
    }

    .header_link:after {
        display: none;
    }

    .header_link:hover,
    .header_link:active,
    .header_link:focus {
        border: none;
        outline: none;
        background-color: transparent;
    }
    .header_link_wrapper {
        padding-left: 20px;
        margin: 20px 0; 
    }

    .header_link_wrapper + .header_link_wrapper {
        padding-left: 20px;
    }

    .header_open_menu {
        display: block;
        cursor: pointer;
        margin-left: 20px;
        transform: rotate(0deg);
        transition: transform .3s ease;
    }

    .header_open_menu_opened {
        transform: rotate(-180deg);
    }

    .header_open_menu svg {
        fill: #fff;
        transition: all .3s ease;
    }

    .header_open_menu:hover svg {
        fill: #25E5FF;
    }
}