.about_hero {
    background-image: url(./images/hero.jpg);
}


.about_content_wrapper {
    background-color: #fff;
}

.about_content {
    margin-bottom: 45px;
}

.about_content p + h2 {
    margin-top: 40px;
}

.about_team {
    margin-top: 20px;
    margin-bottom: 40px;
}

.about_socials {
    margin-top: 30px;
}
