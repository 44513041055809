.footer_wrapper {
    color: #fff;
    padding: 34px 0;
    background-color: #000;
    margin-top: auto;
}

.footer_company {
    margin-top: 34px;
    display: flex;
    align-items: center;
}

.footer_company_logo {
    fill: #fff;
}

.footer_company_name {
    margin: 0 0 0 4px;
    font-weight: bold;
    font-size: 20px;
}

.footer_company_description {
    width: 100%;
    max-width: 370px;
    font-size: 17px;
    line-height: 21px;
    margin-top: 11px;
}

.footer_divider {
    height: 1px;
    background-color: #505050;
    margin: 34px 0;
}

.footer_copyright {
    display: flex;
    font-size: 14px;
    line-height: 1.5;
}

.footer_copyright p {
    font-size: 14px;
    line-height: 1.5;
}

.footer_link {
    margin-left: 29px;
    color: #fff;
    transition: all .3s ease;
}

.footer_link.active,
.footer_link:hover,
.footer_link:active,
.footer_link:focus {
    color: #25E5FF;
}