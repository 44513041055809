.subscribe_modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
}

.subscribe_modal_opened {
    opacity: 1;
    visibility: visible;
}

.subscribe_modal_overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0, .46);
}

.subscribe_modal_content {
    width: 100%;
    max-width: 580px;
    padding: 50px 90px;
    border-radius: 14px;
    position: relative;
    text-align: center;
    color: #fff;
    box-shadow: 0 1px 2px rgba(255,255,255, .25);
    background-color: #000;
}

.subscribe_modal_close {
    position: absolute;
    top: 15px;
    right: 12px;
    width: 44px;
    height: 44px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.subscribe_modal_close svg {
    fill: #fff;
    fill-opacity: 0.4;
}

.subscribe_modal_title {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 24px;
    text-shadow: 0px 2px 2px rgba(0,0,0, .52);
}

.subscribe_modal_description {
    text-shadow: 0px 2px 2px rgba(0,0,0, .52);
}

.subscribe_form_input_wrapper {
    margin-bottom: 24px;
}

.subscribe_form,
.subscribe_form_email,
.subscribe_form_submit {
    display: block;
    width: 100%;
    font-size: 17px;
    line-height: 21px;
    color: #fff;
}

.subscribe_form_email {
    margin: 24px 0 0;
    background-color: transparent;
    border: 1px solid rgba(255,255,255, .25);
    border-radius: 10px;
    padding: 11px 13px 12px;
    outline: none;
}

.subscribe_form_email_error,
.subscribe_form_email_error:focus {
    border-color: red;
}

.subscribe_modal_error {
    color: #FF6666;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
}

::-ms-input-placeholder { /* Edge 12-18 */
    color: #fff;
  }
  
::placeholder {
    color: #fff;
}

.subscribe_form_submit {
    text-align: center;
    border: none;
    outline: none;
    padding: 11px 13px 12px;
    text-shadow: 0 3px 3px rgba(0,0,0, .46);
    border-radius: 10px;
    background: linear-gradient(70deg, #FFB524 0%, #FF5C00 100%);
    font-weight: bold;
}
