.games_wrapper {
    background-color: #fff;
    flex: 1 0 auto;
}

.games_title {
    margin: 45px 0;
}

.games_wrapper .game1,
.games_wrapper .game2 {
    background-color: #fff;
    background-size: cover;
    height: 610px;
}

.games_wrapper .game1 {
    background-image: url(./images/game1.jpg);
}

.games_wrapper .game2 {
    background-image: url(./images/game2.jpg);
}

@media screen and (max-width: 767px) { 
    .games_wrapper .game1,
    .games_wrapper .game2 {
        background-color: #fff;
        height: 300px;
    }
}
